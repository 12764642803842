<template>
  <v-app>
    <k-d-a-navigation-appbar :b-mobile="$vuetify.breakpoint.mdAndDown" />

    <k-d-a-main-view />

    <k-d-a-main-footer />

    <k-d-a-subscribe />
  </v-app>
</template>

<script>
export default {
  name: 'KDAMainLayout',

  components: {
    KDANavigationAppbar: () => import('@/layouts/KDANavigationAppbar'),
    KDAMainView: () => import('@/layouts/KDAMainView'),
    KDAMainFooter: () => import('@/layouts/KDAMainFooter'),
    KDASubscribe: () => import('@/layouts/KDASubscribe')
  }
};
</script>
